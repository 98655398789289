@import "../node_modules/swiper/swiper.scss";
:root {
    // --swiper-theme-color: #fff;
    --swiper-navigation-size: 1rem;
    --swiper-navigation-color: var(--dcscc-primary);
 
    --card-stack-width-mobile: 200px;
    --card-stack-height-mobile: 127px;
    --card-stack-width: 300px;
    --card-stack-height: 190px;
}

#card-stack {
    position: relative;
    z-index: 1;
    background-image: url("/dcscc/v2/images/bg-twisted-links.svg");
    overflow-x: hidden;
    background-size: 140%;
    background-position-x: center;
    background-position-y: 80%;
    background-repeat: no-repeat;

    .card-stack__wrapper {
        align-items: center;
        // background-image: url("../images/homepage/card-stack__bg.png");
        // background-repeat: no-repeat;
        // background-position: 90% 50%;
        display: flex;
        min-height: 500px;
        overflow: hidden;

        @media screen and (min-width: 770px) {
            overflow: initial;
        }
    }
/** /
    #card-stack__woman {
        left: -20%;
        pointer-events: none;
        position: absolute;
        transform-origin: left center;
        z-index: 2;
        top: -10%;
        // display: none;

        @media screen and (min-width: 576px) {
            left: 5%;
        }
        // @media screen and (min-width: 768px) {
        //     left: 5%;
        // }
        @media screen and (min-width: 992px) {
            transform: translateY(-50%);
            top: 50%;
            left: -5%;
        }
    }
/**/
    .swiper {
        width: var(--card-stack-width-mobile);
        height: var(--card-stack-height-mobile);
        overflow: visible;
        margin-left: 40%;
        margin-top: -50%;
        
    // xs: 0,
    // sm: 576px,
    // md: 768px,
    // lg: 992px,
    // xl: 1200px,
    // xxl: 1500px
        // @media screen and (min-width: 576px) {
        //     margin-left: 50%;
        //     margin-top: -20%;
        // }
        @media screen and (min-width: 768px) {
            width: var(--card-stack-width);
            height: var(--card-stack-height);
            margin-left: 30%;
            margin-top: -30%;
        }
        @media screen and (min-width: 992px) {
            // margin-left: 30%;
            margin-top: -20%;
        }
        @media screen and (min-width: 1200px) {
            // margin-left: 40%;
            margin-top: -20%;
        }
    }

    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        transition: all .3s;

        // all next slides
        &.swiper-slide-next ~ .swiper-slide {
            margin-top: 6rem;
            margin-left: 0rem;
            padding: 0;
            padding-left: 0rem;
        }
        //  2nd to the next slide
        &.swiper-slide-next + .swiper-slide {
            margin-top: 2rem;
            margin-left: -2rem;
            padding: 0;
            padding-left: 0rem;
        }
        //  3rd to the next slide
        &.swiper-slide-next + .swiper-slide + .swiper-slide {
            margin-top: 4rem;
            margin-left: -4rem;
            padding: 0;
            padding-left: 4rem;
        }

        img {
            width: var(--card-stack-width-mobile);
            height: var(--card-stack-height-mobile);

            @media screen and (min-width: 768px) {
                width: var(--card-stack-width);
                height: var(--card-stack-height);
            }
        }
    }
/**/
}
